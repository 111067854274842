import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

export interface WheelTypeProps {
  to: string
  icon: any
  title: string
  fixed?: boolean
}

const WheelType = ({ to, icon, title }: WheelTypeProps) => {
  const image = icon ? getImage(icon?.localFile) : false

  return (
    <Link to={to} className="w-full bg-primary after:pb-[100%] relative after:block after:content-['']">
      <div className="absolute left-1/2 -translate-x-1/2 w-full h-full">
        <div className="h-2/4 flex justify-end items-end">
          {image && (
            <GatsbyImage
              className="mx-auto w-12 h-12 sm:w-20 sm:h-20 md:w-32 md:h-32 group-hover:scale-95 transform duration-300 ease-in-out"
              image={image}
              objectFit="contain"
              alt={title}
            />
          )}
        </div>

        <p className="h-2/5 pt-4 md:pt-8 sm:px-8 leading-4 sm:leading-[2.25rem] text-white text-center text-md text-xl sm:text-4xl">
          {title}
        </p>
      </div>
    </Link>
  )
}
export default WheelType
