import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Seo from '@/components/Seo'
import { Seo as SeoType } from '@/types.d'
import WheelType from '@/components/WheelType'
import ItemListing from '@/components/ItemListing'
import usePostSort from '@/hooks/usePostSort'

interface HomeData {
  data: {
    allWpGwWheelInfo: any
    page: {
      heroTitle: string
      title: string
      content: string
      uri: string
      featuredHeroImage?: any
      seo: SeoType
      featuredImage: any
      blocks: any
      blocksJSON: any
    }
    mainMenu?: any
  }
  pageContext: {
    postlang: string
  }
}

const WheelInfosArchive = ({ data }: HomeData) => {
  // Page data
  const { page, allWpGwWheelInfo } = data

  const { mainMenu } = data

  const heroContent = {
    image: page?.featuredImage,
    archive: true,
  }

  // Reorder items
  const wheelInfos = usePostSort(allWpGwWheelInfo?.items)

  return (
    <Layout title={page?.heroTitle || page?.title} hero={heroContent} nav={mainMenu}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      {wheelInfos && (
        <ItemListing>
          {wheelInfos?.map((item: { uri: string; gwWheelInfoIcon: any; title: string }) => (
            <WheelType key={item.uri} to={item.uri} icon={item.gwWheelInfoIcon} title={item.title} />
          ))}
        </ItemListing>
      )}
    </Layout>
  )
}

export const WheelInfosArchiveQuery = graphql`
  query WheelInfosArchive($id: String) {
    page: wpPage(id: { eq: $id }) {
      heroTitle
      id
      content
      title
      uri
      ...featuredHeroImage
      ...seo
    }
    mainMenu: wpMenu(slug: { eq: "main-menu" }) {
      ...navigation
    }
    allWpGwWheelInfo(sort: { order: ASC, fields: menuOrder }) {
      items: nodes {
        id
        uri
        title
        slug
        ...featuredHeroImageWheelInfo
        template {
          templateName
        }
        gwWheelInfoIcon {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default WheelInfosArchive
